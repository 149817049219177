<template>
  <div v-if="optionResponse" class="row q-col-gutter-y-md ">
    <div class="col-12">
      <q-btn
        flat
        dense
        class="full-width"
        @click="resetFilter()"
      >Alle Filter löschen</q-btn>
    </div>

    <div class="col-12">
      <q-separator></q-separator>
    </div>

    <div class="col-12">
      Preis von
      <q-input
        v-model.lazy="filter.price_min" :min="0" debounce="500" dense outlined steps="1" suffix="€"
        type="number"/>
      bis
      <q-input
        v-model.lazy="filter.price_max" :min="filter.price_min" debounce="500" dense outlined steps="1"
        suffix="€" type="number"/>
    </div>

    <div class="col-12">
      <div>
        <q-checkbox
          v-model="filter.has_valid_general_inspection"
          :false-value="null"
          dense
          label="Gültige HU/AU"/>
      </div>
      <div>
        <q-checkbox
          v-model="filter.is_virtual_tour"
          :false-value="null"
          dense
          label="Online-Besichtigung"/>
      </div>
      <div>
        <q-checkbox
          v-model="filter.is_verified"
          :false-value="null"
          dense
          label="Verkäufer verifiziert"/>
      </div>

      <div>
        <q-checkbox
          v-model="filter.is_business"
          :false-value="null"
          dense
          label="Gewerblicher Verkäufer"/>
      </div>

      <div>
        <q-checkbox
          v-model="filter.vehicle__is_serviced"
          :false-value="null"
          dense
          label="Scheckheftgepflegt"/>
      </div>

    </div>

    <div class="col-12">
      <q-separator></q-separator>
    </div>

    <div class="col-12">
      <q-btn
        outline
        class="full-width"
        @click="openExtrasFilterDialog()"
      >Ausbau filter
      </q-btn>
    </div>

    <div class="col-12">
      <q-btn
        outline
        class="full-width"
        @click="openAdvancedFilterDialog()"
      >Fahrzeug filter
      </q-btn>
    </div>

    <div class="col-12">
      <DynamicInput
        v-model.lazy="filter.vehicle__brand"
        :field="optionResponse.vehicle.children.brand"
        use-input
        outlined
        use-chips
        :options="brandOptionsFiltered"
        multiple
        map-options
        emit-value
        dense
        behavior="menu"
        :required="false"
        input-debounce="0"
        @filter="brandFilterFn"
      >
      </DynamicInput>
    </div>
    <div class="col-12">
      <DynamicInput
        v-model.lazy="filter.vehicle__car_type"
        :field="optionResponse.vehicle.children.car_type"
        dense
        emit-value
        map-options
        multiple
        outlined
        behavior="menu"
        use-chips
      />
    </div>

    <div class="col-12">
      <DynamicInput
        v-model.lazy="filter.vehicle__build_type"
        :field="buildTypeField"
        behavior="menu"
        dense
        emit-value
        map-options
        multiple
        outlined
        use-chips
      />
    </div>

    <div class="col-12">
      <q-separator/>
    </div>

    <div class="col-12">
      <q-option-group
        v-model.lazy="filter.is_negotiable"
        dense
        :options=" [
        {
          label: 'Alles',
          value: undefined
        },
        {
          label: 'Verhandlungsbasis',
          value: true
        },
        {
          label: 'Festpreis',
          value: false
        },
      ]"
        label="Verhandlungsbasis"
      />
    </div>

    <div class="col-12">
      <q-separator/>
    </div>

    <div class="col-12">
      <div>
        <q-checkbox
          v-model="filter.vehicle__is_ready_to_go"
          dense
          :false-value="null"
          label="Reisefertig"/>
      </div>
      <div>
        <q-checkbox
          v-model="filter.vehicle__general_inspection_renew"
          :false-value="null"
          dense
          label="HU nach Kauf"/>
      </div>

      <div>
        <q-checkbox
          v-model="filter.vehicle__is_build"
          dense
          :false-value="null"
          label="Ausgebaut"/>
      </div>

      <div>
        <q-checkbox
          v-model="filter.vehicle__is_oldtimer"
          :false-value="null"
          dense
          label="Oldtimer"/>
      </div>


    </div>

    <div class="col-12">
      <q-separator/>
    </div>

    <div class="col-12">
      <q-select
        v-model.lazy="filter.days_ago"
        outlined
        label="Online seit"
        dense
        :options="daysAgoOptions"
        map-options
        emit-value
      />
    </div>

    <div class="col-12">
      <q-separator/>
    </div>


    <div class="col-12">
      <q-input
        v-model.lazy="filter.vehicle__registration__gte"
        debounce="500"
        dense label="Erstzulassung Jahr ab"
        outlined
        maxlength="4"
        placeholder="2010"
        :rules="[(val) => val ? Number.isInteger(parseInt(val)) : true]"
      />
    </div>

    <div class="col-12">
      Kilometerstand von
      <q-input
        v-model.lazy="filter.vehicle__mileage_min" :min="0" debounce="500" dense outlined steps="1" suffix="km"
        type="number"/>
      bis
      <q-input
        v-model.lazy="filter.vehicle__mileage_max" :min="filter.vehicle__mileage_min" debounce="500" dense outlined
        steps="1"
        suffix="km" type="number"/>
    </div>

  </div>
</template>

<script>
import {computed, inject, onMounted, ref, toRef, watch} from 'vue';
import {useQuasar} from 'quasar'
import Adverts from 'src/api/adverts';
import AdvancedFilterDialog from 'src/components/filter/AdvancedFilterDialog.vue'
import ExtrasFilterDialog from 'src/components/filter/ExtrasFilterDialog.vue'
import {useRoute} from 'vue-router';
import DynamicInput from 'components/qutils/form/DynamicInput.vue';


export default {
  name: 'FilterMenu',
  components: {DynamicInput},
  props: {
    modelValue: {required: true},
    resetFn: {type: Function, default: undefined}
  },
  emits: ['update:modelValue', 'resetFilter'],

  setup(props, ctx) {
    const modelValue = toRef(props, 'modelValue')
    const filter = ref({
      vehicle__brand: [],
      vehicle__extras__slug: [],
      ...modelValue.value
    })
    const optionResponse = ref()
    const brandOptions = ref()
    const $q = useQuasar()
    const route = useRoute()

    const daysAgoOptions = [
      {value: undefined, label: 'Alles'},
      {value: 1, label: 'Gestern'},
      {value: 3, label: 'Letzten 3 Tage'},
      {value: 7, label: 'Lezte Woche'},
      {value: 14, label: 'Letzten 2 Wochen'},
      {value: 30, label: 'Letzter Monat'},
      // {value: 90, label: 'Letzten 3 Monate'},
      // {value: 180, label: 'Letztes halbes Jahr'},
      // {value: 360, label: 'Letztes Jahr'},
    ]
    const brandOptionsFiltered = ref()
    const resultsCount = inject('resultsCount', undefined)

    Adverts.getAdvertOptions().then(resp => {
      optionResponse.value = resp.data.actions.POST
      brandOptions.value = parseOptions(optionResponse.value.vehicle.children.brand.choices)
    })

    watch(
      () => filter.value,
      () => {
        ctx.emit('update:modelValue', filter.value)
      },
      {deep: true}
    )

    const parseOptions = (optionResponse) => {
      return optionResponse.map(item => {
          return {
            label: item.display_name,
            value: item.value
          }
        }
      )
    }

    const getUndefinedValFromCheckbox = (val) => {
      if ([null, false].includes(val)) {
        return undefined
      }
      return val
    }

    const brandFilterFn = (val, update) => {
      update(() => {
        if (val === '') {
          brandOptionsFiltered.value = brandOptions.value
        } else {
          const needle = val.toLowerCase()
          brandOptionsFiltered.value = brandOptions.value.filter(
            v => v.label.toLowerCase().indexOf(needle) > -1
          )
        }
      })
    }

    const openExtrasFilterDialog = () => {
      $q.dialog({
        component: ExtrasFilterDialog,
        componentProps: {
          modelValue: filter.value,
          resultsCount,
        }
      })
    }

    const resetFilter = function () {
      filter.value = {
        vehicle__extras__slug: [],
        // vehicle__equipment__color: []
      }
      ctx.emit('resetFilter')
    }

    const openAdvancedFilterDialog = () => {
      $q.dialog({
        component: AdvancedFilterDialog,
        componentProps: {
          modelValue: filter.value,
          resultsCount,
        }
      })
    }

    const buildTypeField = computed(() => {
      const field = optionResponse.value.vehicle.children.build_type
      field.choices = field.choices.filter(obj => obj.value !== 'None')
      return field
    })

    onMounted(() => {
      if (route.query.openExtras) {
        openExtrasFilterDialog()
      }
    })

    return {
      filter,
      optionResponse,
      buildTypeField,
      daysAgoOptions,
      brandOptionsFiltered,
      parseOptions,
      brandFilterFn,
      getUndefinedValFromCheckbox,
      openExtrasFilterDialog,
      openAdvancedFilterDialog,
      resetFilter,
      resultsCount
    }
  }
}
</script>

<style scoped>

</style>
