import {useMainStore} from 'stores/main';
import {useTracking} from 'src/etc/tracking';

export function onUserSignup(user) {
  // set email in store
  const mainStore = useMainStore()
  const {email, uuid} = user

  if (!email || !uuid)
    console.error(`User email or uuid not defined. Got email: ${email} | uuid: ${uuid}`)

  // set for verification page
  mainStore.signupVerifyEmail = email

  const tracker = useTracking()
  tracker.trackUserSignup(email, uuid)
}
