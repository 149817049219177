<template>
  <div class="cropper-wrapper">
    <cropper
      ref="cropper"
      :image-restriction="'fit-area'"
      :src="image"
      :stencil-component="$options.components.CropperStencil"
      class="cropper"
      @ready="loading = false"
    />
  </div>

  <template v-if="loading">
    <div class="relative-position" style="height: 300px">
      <div class="absolute-center">
        <q-spinner style="font-size: 3rem"></q-spinner>
      </div>
    </div>
  </template>

  <div class="flex justify-center">
    <div>
      <q-btn flat round size="lg" @click="rotateLeft">
        <q-icon class="fas fa-rotate-left"></q-icon>
      </q-btn>
    </div>
    <div>
      <q-btn flat round size="lg" @click="rotateRight">
        <q-icon class="fas fa-rotate-right"/>
      </q-btn>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, watch} from 'vue';
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import CropperStencil from 'src/components/utils/CropperStencil.vue';

export default defineComponent({
  // eslint-disable-next-line vue/no-unused-components
  components: {Cropper, CropperStencil},
  props: {
    image: {
      type: [Object, String],
      default: undefined,
    },
  },
  setup(props) {
    const loading = ref(true);
    const cropper = ref(null);
    const cropImage = function () {
      const result = cropper.value.getResult();
      return result.canvas.toDataURL('image/jpeg');
    };

    const rotateLeft = function () {
      if (!cropper.value) return;
      cropper.value.rotate(-90);
    };
    const rotateRight = function () {
      if (!cropper.value) return;
      cropper.value.rotate(90);
    };

    watch(
      () => props.image,
      () => {
        if (cropper.value) {
          cropper.value.refresh();
        }
      }
    );

    return {
      loading,
      cropImage,
      cropper,
      rotateLeft,
      rotateRight,
    };
  },
});
</script>

<style lang="scss" scoped>
.cropper {
  min-height: 50dvh;
  max-height: calc(100dvh - 280px);
  width: 100%;
}

.cropper-wrapper {
  position: relative;
  overflow: hidden;
}
</style>
