import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-muted text-small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component.component), _mergeProps({
      error: _ctx.errors?.length ? true : undefined,
      "error-message": _ctx.errors ? _ctx.errors.join(' ') : undefined,
      "model-value": _ctx.component.attrs.modelValue
    }, _ctx.component.attrs, _toHandlers(_ctx.component.handlers), {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = 
        (val) => {
          _ctx.$emit('update:modelValue', val);
          _ctx.component.attrs.modelValue = val;
        }
      )
    }), _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, name) => {
        return {
          name: name,
          fn: _withCtx((data) => [
            _renderSlot(_ctx.$slots, name, { data: data })
          ])
        }
      })
    ]), 1040, ["error", "error-message", "model-value"])),
    (_ctx.component.component?.name === 'QCheckbox' && _ctx.component.attrs.hint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.component.attrs.hint), 1))
      : _createCommentVNode("", true)
  ]))
}