<template>
  <q-dialog ref="dialogRef" position="bottom" @hide="onDialogHide">
    <q-card class="q-dialog-plugin" style="max-height: 80dvh">
      <q-list separator>
        <q-item
          :active="accountDetail.active_account.is_business === false"
          clickable
          @click="setBusinessAccount(undefined, $router)"
        >
          <q-item-section avatar>
            <q-avatar>
              <q-img
                :src="accountDetail.avatar.path">
              </q-img>
            </q-avatar>
          </q-item-section>
          <q-item-section>
            {{ accountDetail.public_name }}
          </q-item-section>
        </q-item>
        <q-item
          v-for="business in accountDetail?.businesses"
          :key="business.slug"
          v-close-popup
          clickable
          :active="accountDetail.active_account.is_business === true && accountDetail.active_account.slug === business.slug"
          @click="setBusinessAccount(business.slug, $router)"
        >
          <q-item-section avatar>
            <q-avatar>
              <q-img
                :src="business.avatar.path">

              </q-img>
            </q-avatar>
          </q-item-section>
          <q-item-section>
            {{ business.public_name }}
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar class="q-pl-sm">
            <q-icon name="fas fa-plus"/>
          </q-item-section>
          <q-item-section class="text-center text-bold q-py-md" @click="$router.push({name: 'business-register'})">
            Gewerbliches Profil anlegen
          </q-item-section>
        </q-item>

        <q-item clickable @click="mainStore.logout()">
          <q-item-section avatar class="q-pl-sm">
            <q-icon name="fas fa-sign-out-alt"/>
          </q-item-section>
          <q-item-section class="text-center text-bold q-py-md">
            Logout
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent} from 'quasar'
import {setBusinessAccount} from 'src/etc/helper';
import {computed} from 'vue';
import {useMainStore} from 'stores/main';


const mainStore = useMainStore()

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
])

let accountDetail = computed(() => {
  return mainStore.$state.accountDetail;
});


const {dialogRef, onDialogHide} = useDialogPluginComponent()
</script>

<style lang="scss" scoped>
.q-item--active {
  font-weight: bold;
  background-color: $grey-3;
}
</style>
