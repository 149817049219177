import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-06a7b72d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = ["multiple"]
const _hoisted_3 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_4 = {
  key: 0,
  class: "col-12 q-mb-md"
}
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "flex items-center no-wrap" }
const _hoisted_9 = {
  class: "q-px-sm",
  style: {"overflow-wrap":"anywhere"}
}
const _hoisted_10 = {
  key: 0,
  class: "text-center text-negative q-mb-sm"
}
const _hoisted_11 = {
  key: 1,
  class: "row q-col-gutter-md"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 1,
  class: "text-bold text-center",
  style: {"overflow-wrap":"anywhere"}
}
const _hoisted_14 = { class: "text-center" }
const _hoisted_15 = {
  key: 0,
  class: "text-center text-negative q-mb-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!(!$props.multiple && $setup.fileList.length > 0) && (!$props.maxFiles || ($props.multiple && $setup.fileList.length < $props.maxFiles)))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "c-upload-wrapper q-mb-lg bg-grey-3 flex flex-center column cursor-pointer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.click && $setup.click(...args))),
          onDrop: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($setup.drop && $setup.drop(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_q_icon, {
              name: "fas fa-file-arrow-up",
              size: "lg"
            }),
            _createElementVNode("input", _mergeProps({
              ref: "fileInput",
              multiple: $props.multiple,
              class: "hidden",
              name: "files",
              type: "file"
            }, _ctx.$attrs, {
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.changeFile && $setup.changeFile(...args)))
            }), null, 16, _hoisted_2)
          ]),
          _createElementVNode("div", null, _toDisplayString($props.label), 1),
          ($props.multiple && $props.maxFiles)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "(max. " + _toDisplayString($props.maxFiles) + " Dateien insgesamt)", 1))
            : _createCommentVNode("", true)
        ], 32))
      : _createCommentVNode("", true),
    ($setup.fileList?.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          ($setup.validationErrors?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.validationErrors, (err) => {
                  return (_openBlock(), _createBlock(_component_q_banner, {
                    key: err,
                    class: "bg-warning q-mb-sm",
                    rounded: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(err), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, _toDisplayString($props.errors), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (!$props.gallery)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.fileList, (file, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: file.name,
                      class: "text-bold flex items-center justify-between no-wrap q-my-md"
                    }, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", null, [
                          _createVNode(_component_q_icon, {
                            name: "far fa-file",
                            size: "lg"
                          })
                        ]),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(file.name) + " (" + _toDisplayString($setup.fileSize(file)) + ")", 1),
                        ($props.errors && $props.errors[index])
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.errors[index], (err) => {
                                return (_openBlock(), _createElementBlock("div", { key: err }, _toDisplayString(err), 1))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_q_btn, {
                          round: "",
                          flat: "",
                          size: "sm",
                          onClick: ($event: any) => ($setup.removeFile(index))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "fas fa-trash" })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ])
                    ]))
                  }), 128))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fileList, (file, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: file.name,
                        class: "col-12 col-sm-6"
                      }, [
                        (file.type.startsWith('image/'))
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: $setup.getUrl(file),
                              class: "full-width",
                              style: {"max-height":"200px","object-fit":"contain"}
                            }, null, 8, _hoisted_12))
                          : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _createVNode(_component_q_icon, { name: "far fa-file" }),
                              _createTextVNode(" " + _toDisplayString(file.name), 1)
                            ])),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", null, _toDisplayString($setup.fileSize(file)), 1),
                          ($props.errors && $props.errors[index])
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.errors[index], (err) => {
                                  return (_openBlock(), _createElementBlock("div", { key: err }, _toDisplayString(err), 1))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true),
                          ($props.ordering)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createVNode(_component_q_btn, {
                                  flat: "",
                                  round: "",
                                  onClick: ($event: any) => ($setup.setPosImageUp(index))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      name: "fas fa-angle-up",
                                      size: "xs"
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]),
                                _createVNode(_component_q_btn, {
                                  flat: "",
                                  round: "",
                                  onClick: ($event: any) => ($setup.setPosImageDown(index))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      name: "fas fa-angle-down",
                                      size: "xs"
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ], 64))
                            : _createCommentVNode("", true),
                          _createVNode(_component_q_btn, {
                            round: "",
                            flat: "",
                            size: "sm",
                            onClick: ($event: any) => ($setup.removeFile(index))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_icon, { name: "fas fa-trash" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]))
                    }), 128))
                  ]))
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}