import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar_cropper = _resolveComponent("avatar-cropper")!
  const _component_file_uploader_field = _resolveComponent("file-uploader-field")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref: "dialogRef",
    "full-width": !!_ctx.images?.length,
    onHide: _ctx.onDialogHide
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        disabled: _ctx.isLoading,
        class: "q-dialog-plugin"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_avatar_cropper, {
                  ref: "avatarCropper",
                  image: _ctx.imgBase64
                }, null, 8, ["image"])
              ], 512), [
                [_vShow, _ctx.images?.length]
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_file_uploader_field, {
                  modelValue: _ctx.images,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.images) = $event)),
                    _ctx.onChange
                  ],
                  label: "Klicken oder Bild hineinziehen",
                  gallery: false,
                  multiple: false,
                  accept: "image/png, image/jpeg"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                flat: "",
                onClick: _ctx.onCancelClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Abbrechen")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_q_btn, {
                disable: !_ctx.images?.length,
                color: "primary",
                unelevated: "",
                onClick: _ctx.onSave
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Übernehmen")
                ]),
                _: 1
              }, 8, ["disable", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["full-width", "onHide"]))
}