<template>
  <q-toolbar class="q-px-none flex q-gutter-x-xs">
    <q-btn
      v-if="$q.screen.lt.sm && (!$route.meta.hasBackBtn || !hasHistory)"
      dense
      flat
      icon="menu"
      no-caps
      round
      @click="drawer = !drawer"
    />

    <q-btn
      v-if=" ((!$route.meta.hasBackBtn || !hasHistory )) && $q.screen.gt.xs"
      flat
      to="/"
      no-caps
    >
      <div class="flex items-center q-gutter-x-sm">
        <img
          :src="require('assets/campertrader/logo/campertrader_icon_ocean.svg')" width="25"
          alt="Camper Trader Logo">
        <div class="text-left text-inter-bold text-secondary" style="line-height: 0.8rem">
          <div>Camper</div>
          <div style="margin-left: 10px">Trader</div>
        </div>
      </div>
    </q-btn>

    <q-btn
      v-if="$route.meta.hasBackBtn && hasHistory"
      flat
      icon="fas fa-arrow-left"
      round
      @click="$router.go(-1)"
    />


    <q-space/>

    <q-btn
      v-if="!$q.screen.lt.sm"
      :to="{name: 'createAdvert'}"
      flat
      no-caps
    >
      Verkaufen
    </q-btn>

    <template v-if="!$q.screen.lt.sm">
      <q-btn
        :to="{name: 'search'}"
        dense
        flat
        no-caps
      >Suchen
      </q-btn>
    </template>

    <q-btn
      v-if="$q.screen.gt.sm && mainStore.isLoggedIn"
      :to="{name: 'favorites'}"
      flat
      round
    >
      <q-icon name="far fa-bookmark" size="xs"></q-icon>
    </q-btn>

    <template v-if="mainStore.isLoggedIn && mainStore.accountDetail">
      <q-btn
        :to="{name: 'messenger'}"
        flat
        round
      >
        <q-icon name="far fa-message" size="xs"></q-icon>
        <q-badge v-if="mainStore.messagesUnread > 0" color="red" floating style="bottom: 4px; top: unset">
          {{ mainStore.messagesUnread }}
        </q-badge>
      </q-btn>

      <q-btn-dropdown
        class="no-icon"
        dense
        flat
        no-caps
        square
      >
        <template #label>
          <q-avatar size="sm">
            <q-img
              no-transition no-spinner no-native-menu
              :src="mainStore.accountDetail?.active_account?.avatar?.path"></q-img>
          </q-avatar>
          <div v-if="mainStore.isLoggedIn && !$q.screen.lt.md" class="cursor">
            &nbsp; {{
              mainStore.accountDetail?.active_account?.is_business ?
                mainStore.accountDetail?.active_account.public_name :
                mainStore.accountDetail?.email
            }}
          </div>
        </template>

        <q-list>
          <q-item
            v-close-popup :to="getAccountPageParams()"
            clickable>
            <q-item-section>Profil</q-item-section>
          </q-item>

          <q-expansion-item
            v-if="mainStore.accountDetail?.businesses?.length"
            dense-toggle
            expand-separator
            label="Alle Profile"
          >
            <q-list>
              <q-item
                :active="mainStore.accountDetail.active_account.uuid === mainStore.accountDetail.uuid"
                class="bg-grey-3" clickable
                @click="setBusinessAccount(null, $router)"
              >
                <q-item-section>
                  <div class="flex no-wrap" style="column-gap: 8px">
                    <q-avatar size="xs">
                      <q-img :src="mainStore.accountDetail.avatar.path"></q-img>
                    </q-avatar>
                    <div>
                      {{ mainStore.accountDetail.public_name }}
                    </div>
                  </div>
                </q-item-section>
              </q-item>

              <q-item
                v-for="business in mainStore.accountDetail.businesses"
                :key="business.slug"
                :active="mainStore.accountDetail.active_account.uuid === business.uuid " class="bg-grey-3"
                clickable
                @click="setBusinessAccount(business.slug, $router)"
              >
                <q-item-section>
                  <div class="flex no-wrap" style="column-gap: 8px">
                    <q-avatar size="xs">
                      <q-img :src="business.avatar?.path"></q-img>
                    </q-avatar>
                    <div>
                      {{ business.public_name }}
                    </div>
                  </div>
                </q-item-section>
              </q-item>
            </q-list>
          </q-expansion-item>

          <q-item v-close-popup :to="{name: 'search-agents'}" clickable>
            <q-item-section>Suchaufträge</q-item-section>
          </q-item>
          <q-item v-close-popup :to="{name: 'favorites'}" clickable>
            <q-item-section>Dein Parkplatz</q-item-section>
          </q-item>
          <!--            <q-item v-close-popup :to="{name: 'subscription'}" clickable>-->
          <!--              <q-item-section>Abonnements</q-item-section>-->
          <!--            </q-item>-->
          <!--            <q-item v-close-popup :to="{name: 'subscription-plans'}" clickable>-->
          <!--              <q-item-section>Preisliste</q-item-section>-->
          <!--            </q-item>-->

          <q-item v-close-popup :to="{name: 'settings'}" clickable>
            <q-item-section>Einstellungen</q-item-section>
          </q-item>

          <q-separator></q-separator>

          <q-item v-close-popup clickable @click="logout">
            <q-item-section>Logout</q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </template>

    <template v-else>
      <q-btn
        :to="{name: 'login'}"
        class="q-mx-sm"
        unelevated
        no-caps
        color="dark"
      >
        Anmelden
      </q-btn>
    </template>

  </q-toolbar>
</template>

<script>
import {useMainStore} from 'stores/main';
import {computed, inject} from 'vue';
import {getAccountPageParams, setBusinessAccount} from 'src/etc/helper';
import {QHeader} from 'quasar';

const mainStore = useMainStore()

export default {
  name: 'HeaderToolbar',
  components: {},
  props: {
    tag: {default: QHeader}
  },
  setup() {
    const drawer = inject('drawer')

    const logout = function () {
      mainStore.logout();
    }

    const hasHistory = computed(() => {
      return window && window.history?.length > 0 && window.history.state?.back !== null
    })

    return {
      mainStore,
      logout,
      drawer,
      hasHistory,
      setBusinessAccount,
    }
  },
  computed: {
    QHeader() {
      return QHeader
    }
  },
  methods: {getAccountPageParams},
}

</script>

<style scoped lang="scss">
:deep(.q-btn-dropdown__arrow) {
  margin-left: 0;
}
</style>
