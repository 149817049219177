import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 0,
  class: "flex"
}
const _hoisted_4 = {
  key: 1,
  class: "flex q-gutter-sm"
}
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_DynamicInput = _resolveComponent("DynamicInput")!
  const _component_base_dialog_content = _resolveComponent("base-dialog-content")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref: "dialogRef",
    maximized: _ctx.$q.screen.lt.sm,
    "transition-show": "slide-up",
    "transition-hide": "slide-down",
    onHide: $setup.onDialogHide
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_dialog_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!$setup.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__equipment__abs ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__equipment__abs ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__equipment__abs ? 'white' : undefined,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.filter.vehicle__equipment__abs = $setup.filter.vehicle__equipment__abs ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" ABS ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__equipment__esp ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__equipment__esp ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__equipment__esp ? 'white' : undefined,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.filter.vehicle__equipment__esp = $setup.filter.vehicle__equipment__esp ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" ESP ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__all_wheel_drive ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__all_wheel_drive ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__all_wheel_drive ? 'white' : undefined,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.filter.vehicle__all_wheel_drive = $setup.filter.vehicle__all_wheel_drive ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Allrad (4x4) ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__equipment__ac ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__equipment__ac ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__equipment__ac ? 'white' : undefined,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.filter.vehicle__equipment__ac = $setup.filter.vehicle__equipment__ac ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Klimaanlage ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__equipment__cruise_control ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__equipment__cruise_control ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__equipment__cruise_control ? 'white' : undefined,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.filter.vehicle__equipment__cruise_control = $setup.filter.vehicle__equipment__cruise_control ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Tempomat ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__equipment__trailer_hitch ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__equipment__trailer_hitch ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__equipment__trailer_hitch ? 'white' : undefined,
                      onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.filter.vehicle__equipment__trailer_hitch = $setup.filter.vehicle__equipment__trailer_hitch ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Anhängerkupplung ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      color: $setup.filter.vehicle__equipment__parking_assistance ? 'positive' : undefined,
                      icon: $setup.filter.vehicle__equipment__parking_assistance ? 'check_box' : 'check_box_outline_blank',
                      "text-color": $setup.filter.vehicle__equipment__parking_assistance ? 'white' : undefined,
                      clickable: "",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.filter.vehicle__equipment__parking_assistance = $setup.filter.vehicle__equipment__parking_assistance ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Parkassistent ")
                      ]),
                      _: 1
                    }, 8, ["color", "icon", "text-color"]),
                    _createVNode(_component_q_chip, {
                      color: $setup.filter.vehicle__equipment__rear_view_camera ? 'positive' : undefined,
                      icon: $setup.filter.vehicle__equipment__rear_view_camera ? 'check_box' : 'check_box_outline_blank',
                      "text-color": $setup.filter.vehicle__equipment__rear_view_camera ? 'white' : undefined,
                      clickable: "",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.filter.vehicle__equipment__rear_view_camera = $setup.filter.vehicle__equipment__rear_view_camera ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Rückfahrkamera ")
                      ]),
                      _: 1
                    }, 8, ["color", "icon", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__equipment__seat_heating ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__equipment__seat_heating ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__equipment__seat_heating ? 'white' : undefined,
                      onClick: _cache[8] || (_cache[8] = ($event: any) => ($setup.filter.vehicle__equipment__seat_heating = $setup.filter.vehicle__equipment__seat_heating ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Sitzheizung ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__equipment__metallic ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__equipment__metallic ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__equipment__metallic ? 'white' : undefined,
                      onClick: _cache[9] || (_cache[9] = ($event: any) => ($setup.filter.vehicle__equipment__metallic = $setup.filter.vehicle__equipment__metallic ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Metallic ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__particle_filter ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__particle_filter ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__particle_filter ? 'white' : undefined,
                      onClick: _cache[10] || (_cache[10] = ($event: any) => ($setup.filter.vehicle__particle_filter = $setup.filter.vehicle__particle_filter ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Partikelfilter ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__is_oldtimer ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__is_oldtimer ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__is_oldtimer ? 'white' : undefined,
                      onClick: _cache[11] || (_cache[11] = ($event: any) => ($setup.filter.vehicle__is_oldtimer = $setup.filter.vehicle__is_oldtimer ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Oldtimer ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"]),
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      icon: $setup.filter.vehicle__is_build ? 'check_box' : 'check_box_outline_blank',
                      color: $setup.filter.vehicle__is_build ? 'positive' : undefined,
                      "text-color": $setup.filter.vehicle__is_build ? 'white' : undefined,
                      onClick: _cache[12] || (_cache[12] = ($event: any) => ($setup.filter.vehicle__is_build = $setup.filter.vehicle__is_build ? undefined : true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Hat Ausbau ")
                      ]),
                      _: 1
                    }, 8, ["icon", "color", "text-color"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                      return _createVNode(_component_q_skeleton, {
                        key: i,
                        type: 'QChip',
                        width: `${$setup.randomIntFromInterval(50,150)}px`,
                        dense: ""
                      }, null, 8, ["width"])
                    }), 64))
                  ]))
            ]),
            (!$setup.loading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_DynamicInput, {
                      modelValue: $setup.filter.vehicle__equipment__color,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.filter.vehicle__equipment__color) = $event)),
                      field: $setup.optionResponse.vehicle.children.equipment.children.color,
                      outlined: "",
                      dense: "",
                      "emit-value": "",
                      "map-options": "",
                      multiple: ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_DynamicInput, {
                      modelValue: $setup.filter.vehicle__size_length,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.filter.vehicle__size_length) = $event)),
                      field: $setup.optionResponse.vehicle.children.size_length,
                      outlined: "",
                      dense: "",
                      "emit-value": "",
                      "map-options": "",
                      multiple: ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_DynamicInput, {
                      modelValue: $setup.filter.vehicle__size_height,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.filter.vehicle__size_height) = $event)),
                      field: $setup.optionResponse.vehicle.children.size_height,
                      outlined: "",
                      dense: "",
                      "emit-value": "",
                      "map-options": "",
                      multiple: ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_DynamicInput, {
                      modelValue: $setup.filter.vehicle__roof_system,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.filter.vehicle__roof_system) = $event)),
                      field: $setup.optionResponse.vehicle.children.roof_system,
                      dense: "",
                      "emit-value": "",
                      "map-options": "",
                      multiple: "",
                      outlined: ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_DynamicInput, {
                      modelValue: $setup.filter.vehicle__admission,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.filter.vehicle__admission) = $event)),
                      field: $setup.optionResponse.vehicle.children.admission,
                      dense: "",
                      "emit-value": "",
                      "map-options": "",
                      multiple: "",
                      outlined: ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_DynamicInput, {
                      modelValue: $setup.filter.vehicle__transmission,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($setup.filter.vehicle__transmission) = $event)),
                      field: $setup.optionResponse.vehicle.children.transmission,
                      outlined: "",
                      "emit-value": "",
                      multiple: "",
                      "map-options": "",
                      dense: ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_DynamicInput, {
                      modelValue: $setup.filter.vehicle__drive_shaft_position,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.filter.vehicle__drive_shaft_position) = $event)),
                      field: $setup.optionResponse.vehicle.children.drive_shaft_position,
                      dense: "",
                      "emit-value": "",
                      "map-options": "",
                      outlined: ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_DynamicInput, {
                      modelValue: $setup.filter.vehicle__emission_class,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.filter.vehicle__emission_class) = $event)),
                      outlined: "",
                      field: $setup.optionResponse.vehicle.children.emission_class,
                      "emit-value": "",
                      "map-options": "",
                      multiple: "",
                      dense: ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_DynamicInput, {
                      modelValue: $setup.filter.vehicle__environmental_badge,
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($setup.filter.vehicle__environmental_badge) = $event)),
                      outlined: "",
                      field: $setup.optionResponse.vehicle.children.environmental_badge,
                      "emit-value": "",
                      "map-options": "",
                      multiple: "",
                      dense: ""
                    }, null, 8, ["modelValue", "field"])
                  ])
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(10, (i) => {
                  return _createElementVNode("div", {
                    key: i,
                    class: "col-12"
                  }, [
                    _createVNode(_component_q_skeleton, {
                      type: "QInput",
                      height: "35px",
                      dense: ""
                    })
                  ])
                }), 64))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["maximized", "onHide"]))
}